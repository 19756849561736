<template>
  <div class="tw-bg-black tw-flex tw-justify-center">
    <!-- <div class="tw-w-full storycontainer">
      <Story :items="storyItems" :storyId="experience.exp_id" :influencer="experience.influencer_data" ref="story"
                class="story tw-overflow-hidden" />
                   <div class="tw-absolute tw-top-7 tw-right-4">
      <ExitButton :handleTap="exitView" />
    </div>
    </div> -->
     <ExpandStoryCarousel
            @toggle-expand="
              () => {
                expandStoryCarouselEnabled = !expandStoryCarouselEnabled;
              }
            "
            @next-story-slide="
              () => {
                story().next();
              }
            "
            @prev-story-slide="
              () => {
                story().prev();
              }
            "
            :enabled="expandStoryCarouselEnabled"
          >
            <div class="">
              <Story
                :items="storyItems"
                :storyId="experience.exp_id"
                ref="story"
                class="story"
                :active="true"
                :influencer="experience.influencer_data"
                :expandStoryCarouselEnabled="expandStoryCarouselEnabled"
                @toggle-expand="
                  () => {
                    expandStoryCarouselEnabled = !expandStoryCarouselEnabled;
                  }
                "
              />
            </div>
          </ExpandStoryCarousel>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import Story from './InstaStory.vue';
//import ExitButton from '@/views/components/ExitButton.vue';
import ExpandStoryCarousel from '@/views/inspiration/ExpandStoryCarousel';
export default {
  name: 'ExpressStoryView',
  data() {
    return {
      expandStoryCarouselEnabled: true,
    };
  },
  components: {
    Story,
    //ExitButton,
    ExpandStoryCarousel,
  },
  computed: {
    ...mapGetters([
      'experience',
    ]),
    storyItems() {
      return this.experience.story_array.map(item => {
        const isVideo = item.includes('.mov?') || item.includes('.mp4?');
        return {
          url: item,
          type: isVideo ? 'video' : 'image'
        };
      });
    },
  },
  methods: {
    ...mapActions([
      'loadExperience',
    ]),
    exitView() {
      this.$router.go(-1);
    },
    story() {
      return {
        next: () => this.$refs.story.$refs.carousel.nextSlide(),
        prev: () => this.$refs.story.$refs.carousel.prevSlide()
      };
    },
    prevSlide() {
      if (this.currentExperienceIndex === 0) {
        return;
      }
      this.currentExperienceId = this.filteredExperiences[
        this.currentExperienceIndex - 1
      ].exp_id;
    },
    log(obj) {
      console.log(obj);
    },
    nextSlide() {
      if (this.currentExperienceIndex >= this.filteredExperiences.length - 1) {
        return;
      }
      this.currentExperienceId = this.filteredExperiences[
        this.currentExperienceIndex + 1
      ].exp_id;
    },
  },
  async mounted() {
    try {
      await this.loadExperience(this.$route.params.inspirationId);
    } catch (e) {
      console.log(e);
      this.$router.push({ name: 'LandingPage' });
    }
  },
  watch: {
    expandStoryCarouselEnabled: function() {
      if (this.expandStoryCarouselEnabled) {
        document.querySelector('html')?.classList.add('hotel-modal-open');
      } else {
        document.querySelector('html')?.classList.remove('hotel-modal-open');
      }
    }
  },
};
</script>


<style lang="scss" scoped>
@import '~@/assets/styles/grid.scss';
@import '~@/assets/styles/variables.scss';

.storycontainer {
  width: calc(36.76% - 64px);
  min-width: calc(36.76% - 64px);
  margin-right: 64px;
  user-select: none;
  @media (max-width: 760px) {
    min-width: unset;
    width: calc(100vw - 92px);
  }
}
</style>
