var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"tw-bg-black tw-flex tw-justify-center"},[_c('ExpandStoryCarousel',{attrs:{"enabled":_vm.expandStoryCarouselEnabled},on:{"toggle-expand":() => {
              _vm.expandStoryCarouselEnabled = !_vm.expandStoryCarouselEnabled;
            },"next-story-slide":() => {
              _vm.story().next();
            },"prev-story-slide":() => {
              _vm.story().prev();
            }}},[_c('div',{},[_c('Story',{ref:"story",staticClass:"story",attrs:{"items":_vm.storyItems,"storyId":_vm.experience.exp_id,"active":true,"influencer":_vm.experience.influencer_data,"expandStoryCarouselEnabled":_vm.expandStoryCarouselEnabled},on:{"toggle-expand":() => {
                  _vm.expandStoryCarouselEnabled = !_vm.expandStoryCarouselEnabled;
                }}})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }